import axios from 'axios';
import ReactGA from 'react-ga4';
import { Dispatch, SetStateAction } from 'react';
import i18n from 'i18next';
import moment from 'moment';
import { useMediaQuery, useTheme } from '@mui/material';
import { OrderDeliveryAddress } from 'store/features/order/orderTypes';
import { InfoType, PrintOption, ServiceCategory, ServiceOptions } from './types';
import { FileInfo } from '../store/features/file/types';
import { RootState } from '../store/configureStore';

export const getStateAndToken = (thunkAPI: any): [RootState, string] => {
  const state: RootState = thunkAPI.getState() as RootState;
  const { access_token } = state.user.currentUser;
  return [state, access_token];
};

export const getFileIds = (files: FileInfo[] | null | undefined) => {
  if (!files || !Array.isArray(files)) {
    return [];
  }

  return files.map((file: FileInfo) => Number(file.id));
};

export const calculateTotalpagesPerFile = (files: Partial<FileInfo>[] | null | undefined) => {
  if (!files || !Array.isArray(files)) {
    return [];
  }

  return files.map((file: Partial<FileInfo>) => Number(file.number_of_pages || 0));
};

export const calculateTotalPages = (files: FileInfo[] | null | undefined) => {
  if (!files || !Array.isArray(files)) {
    return 0; // Return 0 if files is null, undefined or not an array
  }

  let numberOfPages = 0;

  files.forEach((file: any) => {
    numberOfPages += file.number_of_pages || 0; // Add 0 if file.number_of_pages is undefined
  });

  return numberOfPages === 1 ? 1 : numberOfPages;
};

export const getUrl = () => {
  const path = location.pathname;
  return path;
};

export const useIsDesktop = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('md'));
};

export const getAvailableAndSelectedServices = (
  services: ServiceOptions[],
  currentUser: any,
  orderDeliveryAddress: any,
  service_type: string,
) => {
  if (!currentUser) {
    return { availableServices: [], selectedService: null };
  } else {
    const availableServices = services?.filter(
      (item: ServiceOptions) => item?.options[0]?.country === orderDeliveryAddress.country,
    );

    let selectedService: any = availableServices.find(
      (service) => service.name === service_type,
    )?.available_delivery_types;

    // BG deliveries: Remove "sameday" from available delivery types if city is not a valid destination
    const validSamedayDestinations = ['sofia', 'софия', 'sofiq'];
    if (!validSamedayDestinations.includes(orderDeliveryAddress.city?.toLowerCase())) {
      selectedService = selectedService?.filter((item: any) => item.delivery_type_name !== 'sameday');
    }

    return { availableServices, selectedService };
  }
};

export const formatPrice = (country: string | undefined, price: number) => {
  const roundedPrice = price / 100;
  if (roundedPrice <= 0) {
    return country === 'BG' ? `0.00 ${i18n.t('main.bgCurrency')}` : `£ 0.00`;
  }
  const formattedPrice =
    country === 'BG' ? `${roundedPrice.toFixed(2)} ${i18n.t('main.bgCurrency')}` : `£ ${roundedPrice.toFixed(2)}`;
  return formattedPrice;
};

export const handleFormatDate = (dateString: string): InfoType | undefined => {
  try {
    const targetDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
    const currentDate = moment().startOf('day');
    const tomorrowDate = moment().startOf('day').add(1, 'days');
    if (targetDate.isSame(currentDate, 'day')) {
      return { text: i18n.t('cards.today'), date: targetDate.format('DD-MM-YYYY') };
    } else if (targetDate.isSame(tomorrowDate, 'day')) {
      return { text: i18n.t('cards.tomorrow'), date: targetDate.format('DD-MM-YYYY') };
    } else {
      return { text: i18n.t('cards.onDate'), date: targetDate.format('DD-MM-YYYY') };
    }
  } catch (error) {
    null;
  }
};

// Services
export const getCountry = async () => {
  try {
    const response = await axios.get(`https://ipapi.co/json/?key=${process.env.REACT_APP_IP_API_KEY}`);
    return response.data.country;
  } catch (error) {
    console.error('Error fetching country:', error);
    return null;
  }
};

export const handleAvailableServices = (
  services: ServiceOptions[],
  orderDeliveryAddress: OrderDeliveryAddress,
): ServiceOptions[] => {
  if (!orderDeliveryAddress.country) {
    return services;
  }

  const filteredServices = services?.filter(
    (item: ServiceOptions) => item?.options[0]?.country === orderDeliveryAddress.country,
  );

  return filteredServices;
};

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

export const capitalizeOnlyFirstLetter = (string: string | undefined) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

type DateRange = {
  startDate: Date;
  endDate: Date;
  key: string;
};

type UseFilterChangeParams = {
  setActiveClass: Dispatch<SetStateAction<string>>;
  setFilterValue: Dispatch<SetStateAction<string>>;
  setDate: Dispatch<SetStateAction<DateRange>>;
  tableData: any;
  setOrderQuery?: Dispatch<SetStateAction<string>>;
};

export const useFilterChange = ({
  setActiveClass,
  setFilterValue,
  setDate,
  tableData,
  setOrderQuery,
}: UseFilterChangeParams) => {
  return (input: any) => {
    // Determine if the input is an event object or a direct value
    const isEvent = input && input.target;
    const selectedFilter = isEvent ? input.target.value : input;

    // Apply the logic based on the selectedFilter
    setActiveClass(selectedFilter);
    setFilterValue(selectedFilter);

    switch (selectedFilter) {
      case 'today':
        setDate({
          startDate: moment().startOf('day').toDate(),
          endDate: moment().endOf('day').toDate(),
          key: 'selection',
        });
        if (setOrderQuery) setOrderQuery('today');
        break;
      case 'last7days':
        setDate({
          startDate: moment().subtract(6, 'days').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        });
        if (setOrderQuery) setOrderQuery('week');
        break;
      case 'last4weeks':
        setDate({
          startDate: moment().subtract(4, 'weeks').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        });
        if (setOrderQuery) setOrderQuery('month');
        break;
      case 'last3months':
        setDate({
          startDate: moment().subtract(3, 'months').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        });
        if (setOrderQuery) setOrderQuery('quarter');
        break;
      case 'last12months':
        setDate({
          startDate: moment().subtract(12, 'months').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        });
        if (setOrderQuery) setOrderQuery('year');
        break;
      case 'allTime':
        setDate({
          startDate: moment('2000-01-01').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        });
        if (setOrderQuery) setOrderQuery('all');
        break;
      default:
        tableData;
        break;
    }
  };
};

function range(startAt: number, size: number): Array<number> {
  return [...Array(size - startAt).keys()].map((i) => i + startAt);
}
function getBindingOption(sheetNumber: number): string {
  const bindingOptionToSheetRangeMap = {
    office1_binding_70: range(1, 70),
    office1_binding_70_165: range(70, 165),
    office1_binding_165_240: range(165, 240),
    office1_binding_240_330: range(240, 330),
    office1_binding_330_450: range(330, 451),
  };

  for (const [option, sheetRange] of Object.entries(bindingOptionToSheetRangeMap)) {
    if (sheetRange.includes(sheetNumber)) {
      return option;
    }
  }
  return '';
}

export function calculateBindingPriceBg(
  documents: Array<FileInfo>,
  copies: number,
  is_duplex: boolean,
  serviceOptions: Array<PrintOption>,
) {
  let bindingOptionName = 'office1_binding_70',
    bindingPrice = 0,
    sheetNumber = 1;

  documents.forEach((document: FileInfo) => {
    let documentBindingPrice = 0;

    if (is_duplex) {
      sheetNumber = Math.round(document.number_of_pages / 2);
    } else {
      sheetNumber = document.number_of_pages;
    }

    bindingOptionName = getBindingOption(sheetNumber);

    serviceOptions.forEach((item: PrintOption) => {
      if (item.name === bindingOptionName) {
        documentBindingPrice = calculateStartingPrice(documents.length * copies, item.price_categories);
      }
    });
    bindingPrice += documentBindingPrice;
  });

  return bindingPrice;
}
export function calculateSheetNumber(uploadedFiles: Array<FileInfo>, isDuplex: boolean) {
  let sheetNumber = 0;
  uploadedFiles.forEach((file: FileInfo) => {
    const filePages = isDuplex ? Math.ceil(file.number_of_pages / 2) : file.number_of_pages;
    sheetNumber += filePages;
  });
  return sheetNumber;
}
export function calculateStartingPrice(totalPages: number, range: Array<ServiceCategory> | undefined | number) {
  let price: any = 0;
  if (range && typeof range !== 'number') {
    range.forEach((x: ServiceCategory) => {
      if ((totalPages >= x.start && totalPages < x.end) || (totalPages >= x.start && x.end === null)) {
        price = x.price;
      }
    });
  } else {
    price = range;
  }
  return price;
}

export const TrackGoogleAnalyticsEvent = (event_name: any, data: any) => {
  // Push data to the data layer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event_name,
    ...data,
  });

  // Send GA4 Event
  ReactGA.event(event_name, data);
};
